export const dressPage1 = [
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l0wrafk0/dress/l/2/o/3xl-m2s13003-peach-madame-original-imagchhhwbypcann.jpeg?q=70",
    "brand": "MADAME",
    "title": "Women Asymmetric Pink Dress",
    "color": "",
    "discountedPrice": 1299,
    "price": 2499,
    "discountPersent": 50,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/kurta/1/h/g/m-pw333-purshottam-wala-original-imag8zf6ybkmhehy-bb.jpeg?q=70",
    "brand": "Daevish",
    "title": "Women Maxi Blue Dress",
    "color": "",
    "discountedPrice": 341,
    "price": 1899,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/z/s/i/s-a1-zwerlon-original-imagn9uycxbhshur.jpeg?q=70",
    "brand": "ZWERLON",
    "title": "Women A-line Purple Dress",
    "color": "",
    "discountedPrice": 499,
    "price": 1999,
    "discountPersent": 75,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/q/7/v/s-pwd322-purshottam-wala-original-imagm7ypaavduvnm.jpeg?q=70",
    "brand": "Purshottam Wala",
    "title": "Women Fit and Flare Black Dress",
    "color": "",
    "discountedPrice": 359,
    "price": 2099,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/e/a/p/s-pwd322-purshottam-wala-original-imagm6ywr7vz95sz.jpeg?q=70",
    "brand": "Purshottam Wala",
    "title": "Women Fit and Flare Blue Dress",
    "color": "",
    "discountedPrice": 359,
    "price": 2099,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kdlzte80/ethnic-set/p/f/q/l-3209-mint-02-neysa-original-imafuh85unfjczkd.jpeg?q=70",
    "brand": "NEYSA",
    "title": "Women Two Piece Dress Light Green Dress",
    "color": "",
    "discountedPrice": 827,
    "price": 1799,
    "discountPersent": 54,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l0igvww0/dress/g/p/u/xl-wd-1001-leriya-fashion-original-imagc9zbhfhsy33w.jpeg?q=70",
    "brand": "Leriya Fashion",
    "title": "Women A-line Maroon Dress",
    "color": "",
    "discountedPrice": 351,
    "price": 1599,
    "discountPersent": 78,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l2hwwi80/dress/6/q/q/l-310tk6042-selvia-original-imagdtyyqfy7ahwf.jpeg?q=70",
    "brand": "Selvia",
    "title": "Women A-line Black Dress",
    "color": "",
    "discountedPrice": 499,
    "price": 2497,
    "discountPersent": 80,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/a/c/m/l-3261-buynewtrend-original-imagnrpdhdeke5be.jpeg?q=70",
    "brand": "BuyNewTrend",
    "title": "Women Sheath Dark Blue Dress",
    "color": "",
    "discountedPrice": 449,
    "price": 1499,
    "discountPersent": 70,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kuyf8nk0/dress/x/4/c/s-21auw15994-116106-w-original-imag7ymmr5gggzzg.jpeg?q=70",
    "brand": "W",
    "title": "Women A-line Green, White Dress",
    "color": "",
    "discountedPrice": 719,
    "price": 1999,
    "discountPersent": 64,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/9/f/g/xl-271-shivay-style-original-imafnf5ne9wqyw6n-bb.jpeg?q=70",
    "brand": "Sheetal Associates",
    "title": "Women Maxi Grey Dress",
    "color": "",
    "discountedPrice": 349,
    "price": 1999,
    "discountPersent": 65,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/kurta/b/5/w/xl-322k772-saara-original-imafz3xfdnekz6by-bb.jpeg?q=70",
    "brand": "Selvia",
    "title": "Women Asymmetric Black Dress",
    "color": "",
    "discountedPrice": 299,
    "price": 1997,
    "discountPersent": 85,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/4/t/a/xl-164tk2824-selvia-original-imagfuhwektxvjkk-bb.jpeg?q=70",
    "brand": "Selvia",
    "title": "Women A-line Pink Dress",
    "color": "",
    "discountedPrice": 499,
    "price": 2330,
    "discountPersent": 78,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/r/g/b/xxl-pw367-purshottam-wala-original-imaggczp39gmfj7g.jpeg?q=70",
    "brand": "Purshottam Wala",
    "title": "Women Fit and Flare Blue Dress",
    "color": "",
    "discountedPrice": 359,
    "price": 1799,
    "discountPersent": 80,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/q/j/q/xl-bebo-black-pinpoint-seller-original-imagkngh5pp8dd2v.jpeg?q=70",
    "brand": "OK QUEST",
    "title": "Women Fit and Flare Black, Red Dress",
    "color": "",
    "discountedPrice": 474,
    "price": 1289,
    "discountPersent": 47,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/1/0/x/xxl-20auw14014-311749-w-original-imagjzyvh8zctpcx.jpeg?q=70",
    "brand": "W",
    "title": "Women A-line Yellow Dress",
    "color": "",
    "discountedPrice": 827,
    "price": 2799,
    "discountPersent": 70,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/p/0/i/xl-wd1001-1012-leriya-fashion-original-imagj79jnnujedg5.jpeg?q=70",
    "brand": "Leriya Fashion",
    "title": "Women A-line Pink Dress",
    "color": "",
    "discountedPrice": 351,
    "price": 2999,
    "discountPersent": 64,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kshtxu80/dress/i/d/z/m-dv320-daevish-original-imag6fxn7fnrggra.jpeg?q=70",
    "brand": "Purshottam Wala",
    "title": "Women Ethnic Dress Black Dress",
    "color": "",
    "discountedPrice": 359,
    "price": 1799,
    "discountPersent": 80,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/i/t/4/l-3252-buynewtrend-original-imagnrpd2zqyh8dr.jpeg?q=70",
    "brand": "BuyNewTrend",
    "title": "Women Bodycon Black Dress",
    "color": "",
    "discountedPrice": 449,
    "price": 1499,
    "discountPersent": 70,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kfcv6vk0/dress/a/8/s/s-11271756-dressberry-original-imafvtgrbhmvssh9.jpeg?q=70",
    "brand": "Dressberry",
    "title": "Women Fit and Flare Blue Dress",
    "color": "",
    "discountedPrice": 959,
    "price": 1999,
    "discountPersent": 52,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/t/8/i/m-a1-zwerlon-original-imagn5bbthzeqpqe.jpeg?q=70",
    "brand": "ZWERLON",
    "title": "Women A-line Light Green, White Dress",
    "color": "",
    "discountedPrice": 499,
    "price": 1999,
    "discountPersent": 75,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/krme93k0/dress/p/i/d/3xl-lucy-021022023-purvaja-original-imag5dfgzhsghtcz.jpeg?q=70",
    "brand": "PURVAJA",
    "title": "Women Fit and Flare Multicolor Dress",
    "color": "",
    "discountedPrice": 371,
    "price": 2699,
    "discountPersent": 86,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kbs9k7k0/dress/9/f/j/xxl-drdrmsk-13-rudraaksha-original-imaftfczugs8mfnr.jpeg?q=70",
    "brand": "Rudraaksha",
    "title": "Women Fit and Flare Pink Dress With Mask",
    "color": "",
    "discountedPrice": 348,
    "price": 1999,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/ktbu6q80/dress/e/7/h/xxl-78-j-turritopsis-original-imag6p4ehut2gu9s.jpeg?q=70",
    "brand": "J TURRITOPSIS",
    "title": "Women Skater Multicolor Dress",
    "color": "",
    "discountedPrice": 495,
    "price": 1199,
    "discountPersent": 58,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/i/s/d/xs-22auw18583-119023-w-original-imaghnga8wscg5sk.jpeg?q=70",
    "brand": "W",
    "title": "Women Gown Blue Dress",
    "color": "",
    "discountedPrice": 2699,
    "price": 5999,
    "discountPersent": 55,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/7/m/2/xl-aa-00153-maroon-aayu-original-imagz844bgkeehx5.jpeg?q=70",
    "brand": "AAYU",
    "title": "Women A-line Maroon, White Dress",
    "color": "",
    "discountedPrice": 439,
    "price": 1499,
    "discountPersent": 70,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kxtaxzk0/dress/c/d/m/l-keri-vaani-creation-original-imaga6ngag7wtcgh.jpeg?q=70",
    "brand": "Vaani Creation",
    "title": "Women Shift Beige Dress",
    "color": "",
    "discountedPrice": 299,
    "price": 1999,
    "discountPersent": 85,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l13whow0/dress/m/4/o/xxl-21aud10360-501943-aurelia-original-imagcqvjnssagkqt.jpeg?q=70",
    "brand": "Aurelia",
    "title": "Women Fit and Flare Grey Dress",
    "color": "",
    "discountedPrice": 1599,
    "price": 3999,
    "discountPersent": 60,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l3xcr680/dress/p/l/3/l-22few17901-118335-w-original-imagexn4zahxcu3a.jpeg?q=70",
    "brand": "W",
    "title": "Women Fit and Flare Yellow Dress",
    "color": "",
    "discountedPrice": 1779,
    "price": 4999,
    "discountPersent": 64,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/u/u/a/xs-dk333-dk-fab-original-imag89k7mw5hc3zd.jpeg?q=70",
    "brand": "Daevish",
    "title": "Women Maxi Maroon Dress",
    "color": "",
    "discountedPrice": 341,
    "price": 1899,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/k6xxua80/dress/z/z/3/l-286-shivay-style-original-imafp9yphyyf9ypv.jpeg?q=70",
    "brand": "Sheetal Associates",
    "title": "Women A-line Purple Dress",
    "color": "",
    "discountedPrice": 349,
    "price": 3999,
    "discountPersent": 65,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/k/z/r/l-mcaw19d08-57-217-miss-chase-original-imafz2ftwd7j8ghy-bb.jpeg?q=70",
    "brand": "Miss Chase",
    "title": "Women Gown Brown Dress",
    "color": "",
    "discountedPrice": 899,
    "price": 2899,
    "discountPersent": 68,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/d/h/f/m-155tk2854a-selvia-original-imagha9zz8kshheh.jpeg?q=70",
    "brand": "Selvia",
    "title": "Women Ruffled Brown Dress",
    "color": "",
    "discountedPrice": 349,
    "price": 2063,
    "discountPersent": 83,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kngd0nk0/dress/c/l/4/3xl-1036mtbmrnsolidd-momtobe-original-imag24jygj4ujykz.jpeg?q=70",
    "brand": "MomToBe",
    "title": "Women Fit and Flare Maroon Dress",
    "color": "",
    "discountedPrice": 845,
    "price": 2798,
    "discountPersent": 69,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/kxf0jgw0/dress/v/h/a/s-21auw16333-116923-w-original-imag9vjtc4zj5enb.jpeg?q=70",
    "brand": "W",
    "title": "Women Maxi Purple Dress",
    "color": "",
    "discountedPrice": 1558,
    "price": 4599,
    "discountPersent": 65,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/l3es13k0/dress/s/q/w/xxl-wd1001-leriya-fashion-original-imagej6kq6ph89xh.jpeg?q=70",
    "brand": "Leriya Fashion",
    "title": "Women A-line Blue Dress",
    "color": "",
    "discountedPrice": 351,
    "price": 4999,
    "discountPersent": 64,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/image/612/612/xif0q/dress/i/5/w/m-dv333-daevish-original-imaga8zhya5kgvgw-bb.jpeg?q=70",
    "brand": "Daevish",
    "title": "Women Maxi Black Dress",
    "color": "",
    "discountedPrice": 341,
    "price": 1899,
    "discountPersent": 82,
    "size": [
      {
        "name": "S",
        "quantity": 20
      },
      {
        "name": "M",
        "quantity": 30
      },
      {
        "name": "L",
        "quantity": 50
      }
    ],
    "quantity": 100,
    "topLavelCategory": "Women",
    "secondLavelCategory": "Clothing",
    "thirdLavelCategory": "Dress",
    "description":
      "Make a statement with this stylish purple A-line dress from ZWERLON. Made with high-quality fabric, this dress is perfect for any occasion."
  },
];
